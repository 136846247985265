<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('BarcReport.approval_app_report') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="4">
              <b-form-group
              class="row"
              label-cols-sm="3"
              label-cols-md="4"
              :label="$t('globalTrans.from_date')"
              label-for="from_date"
              >
              <flat-pickr class="form-control"
                  v-model="search.from_date"
                  :placeholder="$t('globalTrans.select_date')"
              ></flat-pickr>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
              <b-form-group
              class="row"
              label-cols-sm="3"
              label-cols-md="4"
              :label="$t('globalTrans.to_date')"
              label-for="to_date"
              >
              <flat-pickr class="form-control"
                          v-model="search.to_date"
                          :placeholder="$t('globalTrans.select_date')"
              ></flat-pickr>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('BarcReport.approval_app_report') }}</h4>
          </template>
          <template v-slot:headerAction>
              <b-button class="btn_add_new mr-2" @click="pdfExport">
                <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
              </b-button>
              <export-excel
                class="btn btn_add_new"
                :data="excelData"
                :title="headerValue"
                worksheet="Report Sheet"
                :default-value="headerExcelDefault"
                name="approval-application-report.xls">
                <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
              </export-excel>
            </template>
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row v-show="!loading">
                  <b-col>
                    <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="4">
                      {{ $t('BarcReport.approval_app_report')}}
                    </list-report-head>
                  </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row mt-5>
                <b-col md="12" class="table-responsive">
                  <div>
                      <b-table-simple bordered hover small caption-top responsive>
                        <b-thead>
                          <b-tr>
                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th>{{ $t('grantDistribution.applicant_id') }}</b-th>
                            <b-th>{{ $t('grantDistribution.applicant_name') }}</b-th>
                            <b-th >{{ $t('globalTrans.designation') }}</b-th>
                            <b-th >{{ $t('BarcReport.app_addres') }}</b-th>
                            <b-th >{{ $t('globalTrans.organization') }}</b-th>
                            <b-th >{{ $t('BarcReport.field_of_study') }}</b-th>
                            <b-th >{{ $t('BarcReport.university') }}</b-th>
                            <b-th >{{ $t('BarcReport.date_of_admission') }}</b-th>
                            <b-th >{{ $t('BarcReport.deputation') }}</b-th>
                          </b-tr>
                          <b-tr  v-for="(info, index) in reportData" :key="index">
                            <b-td> {{ $n(index + 1) }} </b-td>
                            <b-td> {{ $n(info.app_gen_id, { useGrouping: false }) }} </b-td>
                            <b-td> {{ ($i18n.locale === 'bn') ? info.name_bn : info.name }} </b-td>
                            <b-td> {{ ($i18n.locale === 'bn') ? info.designation_bn : info.designation }} </b-td>
                            <b-td> {{ ($i18n.locale === 'bn') ? info.division_bn : info.division_en }} </b-td>
                            <b-td> {{ getOrganizationName(info.can_org_type, info.can_org_id) }} </b-td>
                            <b-td> {{ ($i18n.locale === 'bn') ? info.field_of_study_bn : info.field_of_study }} </b-td>
                            <b-td> {{ ($i18n.locale === 'bn') ? info.uni_bn : info.uni_en }} </b-td>
                            <b-td> {{ info.admission_date ? getDate(info.admission_date) : '' }} </b-td>
                            <b-td> {{ info.deputation_start_date ? getDate(info.deputation_start_date) : '' }} - {{ info.deputation_end_date ? getDate(info.deputation_end_date) : '' }} </b-td>
                          </b-tr>
                        </b-thead>
                      </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applicantApprovalApi } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  components: { ListReportHead },
  data () {
    return {
      showData: false,
      showHeading: false,
      search: {
        org_id: 4,
        from_date: '',
        to_date: ''
      },
      incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
      tempList: [],
      rows: [],
      reportData: [],
      loading: false,
      headerExcelDefault: {
        orgName: null,
        orgNameBn: null,
        address: null,
        address_bn: null
      }
    }
  },
  computed: {
    headerValue: function () {
      const headerVal = []
      if (this.$i18n.locale === 'en') {
          headerVal[0] = this.headerExcelDefault.orgName
          headerVal[1] = this.headerExcelDefault.address
          headerVal[2] = this.$t('BarcReport.grant_application_status_report')
        } else {
          headerVal[0] = this.headerExcelDefault.orgNameBn
          headerVal[1] = this.headerExcelDefault.address_bn
          headerVal[2] = this.$t('BarcReport.grant_application_status_report')
        }
        return headerVal
      },
      excelData: function () {
        const listData = this.reportData
        var serial = 0
        const listContractor = listData.map(keyItem => {
          serial++
           if (this.$i18n.locale === 'en') {
              return {
                'sl ': serial,
                'Applicants ID': keyItem.app_gen_id,
                'Applicants Name': keyItem.name,
                'Designation ': keyItem.designation,
                'Applicant Address ': keyItem.division_en,
                'Institute ': this.getOrganizationName(keyItem.can_org_type, keyItem.can_org_id),
                'Field Of Study ': keyItem.field_of_study,
                'University ': keyItem.uni_en,
                'Date Of Admission': keyItem.admission_date !== null ? keyItem.admission_date : ' ',
                'Deputation ': keyItem.deputation_start_date !== null ? keyItem.deputation_start_date : ' ' + '' + (keyItem.deputation_end_date !== null ? +' - ' + keyItem.deputation_end_date : ' ')
              }
            } else {
              return {
                'ক্রমিক সংখ্যা': this.$n(serial),
                'আবেদনকারীদের আইডি ': this.$n(keyItem.app_gen_id, { useGrouping: false }),
                'আবেদনকারীর নাম': keyItem.name_bn,
                'উপাধি ': keyItem.designation_bn,
                'আবেদনকারীর ঠিকান': keyItem.division_bn,
                'ইনস্টিটিউট ': this.getOrganizationName(keyItem.can_org_type, keyItem.can_org_id),
                'অধ্যয়নের ক্ষেত্র ': keyItem.field_of_study_bn,
                'বিশ্ববিদ্যালয় ': keyItem.uni_bn,
                'ভর্তি তারিখ ': keyItem.admission_date !== null ? keyItem.admission_date : ' ',
                'প্রতিপত্তি তারিখ ': keyItem.deputation_start_date !== null ? keyItem.deputation_start_date : ' ' + '' + (keyItem.deputation_end_date !== null ? +' - ' + keyItem.deputation_end_date : ' ')
              }
            }
        })
        return listContractor
      }
  },
  created () {
  },
  mounted () {
    core.index()
    this.headerDataExcel()
  },
  methods: {
    getDate (data) {
      return this.$options.filters.dateFormat(data)
    },
    headerDataExcel () {
        RestApi.getData(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail/4').then(response => {
          if (response.success) {
            const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 4)
            const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
            const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
            this.headerExcelDefault.orgName = orgName
            this.headerExcelDefault.orgNameBn = orgNameBn
            this.headerExcelDefault.address = response.data.address
            this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
    },
    pdfExport () {
        const reportTitle = this.$t('BarcReport.approval_app_report')
        ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 4, reportTitle, this.reportData, this)
    },
    searchData () {
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    async loadData () {
      this.loading = true
      const result = await RestApi.getData(incentiveGrantServiceBaseUrl, applicantApprovalApi, this.search)
      if (result.success) {
        // this.reportData = result.data.data
        this.reportData = this.getRelationalData(result.data.data)
        // this.paginationData(result.data)
      } else {
        this.reportData = []
      }
      this.loading = false
    },
    getRelationalData (data) {
        const divList = this.$store.state.commonObj.divisionList
        const uniList = this.$store.state.incentiveGrant.commonObj.enlistedUniversityList.filter(item => item.status === 1)
         const listData = data.map(item => {
              const divData = divList.find(div => div.value === item.division_id)
              const divObj = {
                division_en: divData !== undefined ? divData.text_en : '',
                division_bn: divData !== undefined ? divData.text_bn : ''
              }
              const uniData = uniList.find(uni => uni.value === item.university_id)
              const uniObj = {
                uni_en: uniData !== undefined ? uniData.text_en : '',
                uni_bn: uniData !== undefined ? uniData.text_bn : ''
              }
              return Object.assign({}, item, divObj, uniObj)
          })
          return listData
    },
    getOrganizationName (orgType, orgId) {
      this.loading = false
      if (orgType === 2) {
        const org = this.$store.state.incentiveGrant.commonObj.narseInstituteList.find(item => item.value === orgId)
        return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
      } else {
        const org = this.$store.state.orgList.find(item => item.value === orgId)
        return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
      }
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
