import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/common-config/report-heading/detail', orgId, reportTitle, newDatas, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          }
        ]

        // if (reportHeadData.projectName) {
        //   pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        // }

        pdfContent.push({ text: reportHeadData.address, style: 'address', alignment: 'center' })

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('grantDistribution.applicant_id'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('grantDistribution.applicant_name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('BarcReport.app_addres'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('BarcReport.field_of_study'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('BarcReport.university'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('BarcReport.date_of_admission'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('BarcReport.deputation'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        newDatas.forEach((info, index) => {
          allRows.push([
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: vm.$n(info.app_gen_id, { useGrouping: false }), alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.name_bn : info.name, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.designation_bn : info.designation, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.division_bn : info.division_en, alignment: 'center', style: 'td' },
            { text: vm.getOrganizationName(info.can_org_type, info.can_org_id), alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.field_of_study_bn : info.field_of_study, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.uni_bn : info.uni, alignment: 'center', style: 'td' },
            { text: info.admission_date ? dateFormat(info.admission_date) : ' ', alignment: 'center', style: 'td' },
            { text: info.deputation_start_date !== null ? dateFormat(info.deputation_start_date) : ' ' + '' + (info.deputation_end_date !== null ? +'-' + dateFormat(info.deputation_end_date) : ' '), alignment: 'center', style: 'td' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['5%', '10%', '10%', '10%', '10%', '15%', '10%', '10%', '10%', '10%'],
            body: allRows
          }
        })
        var docDefinition = {
          content: pdfContent,
          pageSize: 'A4',
          pageOrientation: 'Landscape',
          styles: {
            th: {
              fontSize: (i18n.locale === 'bn') ? 10 : 9,
              margin: [3, 3, 3, 3]
            },
            td: {
              fontSize: (i18n.locale === 'bn') ? 10 : 9,
              margin: [3, 3, 3, 3]
            },
            fertilizer: {
              margin: [5, 0, 0, 5]
            },
            header2: {
              fontSize: 14,
              margin: [0, 10, 0, 20]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            header3: {
              fontSize: 9,
              margin: [0, -20, 4, 5]
          }
          }
       }
      pdfMake.createPdf(docDefinition, null, null, null).download('approval-application-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails,
  methods: {
    getOrganizationName (orgType, orgId) {
      this.loading = false
      if (orgType === 2) {
        const org = this.$store.state.incentiveGrant.commonObj.narseInstituteList.find(item => item.value === orgId)
        return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
      } else {
        const org = this.$store.state.orgList.find(item => item.value === orgId)
        return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
      }
    }
  }
}
